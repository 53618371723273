<template>
  <div style="width:100%;height:100%">
    <div class="nav">
      <input
        class="form-control"
        type="text"
        value=""
        placeholder="输入搜索内容"
      />
      <img class="nav-img" src="../../assets/images/加号.png" alt="" />
    </div>
    <!-- 导航 -->
    <ul class="ul-title">
      <li
        :class="{ active: current == item.id }"
        v-for="item in list"
        :key="item.id"
        @click="libtn(item.id)"
      >
        <a href="#">{{ item.name }}</a>
      </li>
    </ul>
    <div class="content">
      <div class="content-top">
        <p>最新</p>
        <span>知名专家在线讲座</span>
      </div>
      <div class="content-img">
        <img
          src="https://hrp.weilaihos.com/uploads/20201215/b3ce0390ac5115aea882ea0895c3f6c6.jpg"
        />
      </div>
      <p class="content-time">一周前</p>
      <div class="content-bottom">
        <span>点赞 0</span>
        <span>评论 0</span>
        <span>浏览 1</span>
      </div>
    </div>
    <div class="content-2">
      <div class="content-top">
        <p>最新</p>
        <span>在生活面前，吃好，一定是正道</span>
      </div>
      <div class="content-img-2">
        <img
          src="https://hrp.weilaihos.com/uploads/20201209/53c99937271fccc6f8b8ccdeaa766833.jpg"
        />
        <img
          src="https://hrp.weilaihos.com/uploads/20201209/01214824fa4c9f6b6cd5fed466c320be.jpg"
        />
        <img
          src="https://hrp.weilaihos.com/uploads/20201209/3c8cfac0661d390267a67420fb80e398.jpg"
        />
      </div>
      <p class="content-time">2月前</p>
      <div class="content-bottom">
        <span>点赞 0</span>
        <span>评论 0</span>
        <span>浏览 1</span>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from '@/views/bottom1'

export default {
  components: {
    bottom
  },
  data() {
    return {
      // active: 2
      list: [
        { id: 1, name: '关注' },
        { id: 2, name: '推荐' },
        { id: 3, name: '健康互动' },
        { id: 4, name: '医院' }
      ],
      current: 2,
      style: 'color:#3871e1'
    }
  },
  methods: {
    libtn(id) {
      this.current = id
    }
  }
}
</script>
<style scoped>
.nav {
  height: 53px;
  width: 100%;
  background: #5ca5ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4vw 0 5vw;
  box-sizing: border-box;
}
.nav-img {
  height: 25px;
  width: 25px;
}
.form-control {
  width: 70%;
  height: 26px;
  line-height: 26px;
  color: #666;
  font-size: 14px;
  border-radius: 25px;
  border: 0;
  padding-left: 15px;
}
.ul-title {
  height: 38px;
  line-height: 38px;
  margin-bottom: 1rem;
}
.ul-title li {
  float: left;
  margin-left: 21px;
  font-size: 18px;
}
.ul-title li a {
  color: #333333;
}
.ul-title .active a {
  color: #5ca5ff;
}
.content {
  width: 100%;
  height: 269px;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  box-sizing: border-box;
}
.content-top {
  height: 20px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.content-top p {
  color: #ff5a5a;
  margin: 0;
  font-weight: bolder;
  margin-right: 0.5rem;
}
.content-2 {
  height: 191px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  box-sizing: border-box;
}
.content-img {
  height: 156px;
}
.content-img-2 {
  height: 78px;
  display: flex;
  justify-content: space-between;
}
.content-img-2 img {
  height: 100%;
  width: 30%;
}
.content-img img {
  display: block;
  height: 100%;
  width: 100%;
}
.content-time {
  margin: 5px 0;
  height: 25px;
  font-size: 14px;
  color: #666666;
}
.content-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-bottom span {
  font-size: 14px;
  color: #999999;
}
</style>
